<template>
  <Main>
    <a-row :gutter="25">
      <a-col :xs="24">
        <a-form
          ref="formRef"
          :model="form"
          :hideRequiredMark="false"
          :scrollToFirstError="true"
          :rules="formRules"
        >
          <WizardBlock>
            <sdCards headless>
              <a-row justify="center">
                <a-col :xxl="24" :xs="24">
                  <div>
                    <WizardWrapper>
                      <WizardView>
                        <Steps
                          ref="stepView"
                          isSwitch
                          isVertical
                          :current="currentStep"
                          :status="status"
                          :steps="steps"
                          :showPrevBtn="showPrevBtn"
                          @onNext="next"
                          @onDone="done"
                          @onPrev="prev"
                          @onChange="onStepChange"
                          @onDraft="draft"
                          @onPreview="openFrontPage"
                          :isFinished="isFinished"
                        >
                          <template #settings>
                            <BasicFormWrapper class="basic-form-inner">
                              <TabBasic
                                v-model:activeKey="settingsActiveKey"
                                tabPosition="top"
                                v-on:change="settingTabChange"
                              >
                                <Child key="campaign_details">
                                  <template #tab
                                    ><span>Campaign details</span></template
                                  >
                                  <div class="create-account-form">
                                    <a-row :gutter="15">
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="name"
                                          label="Campaign Name"
                                        >
                                          <a-input
                                            v-model:value="form.name"
                                            placeholder="Enter campaign name"
                                            @keyup="getCamapignSlug"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="slug"
                                          label="Campaign Slug"
                                        >
                                          <a-input
                                            v-model:value="form.slug"
                                            placeholder="Enter campaign Slug"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :lg="8"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="redirect_second"
                                          label="Redirect Second"
                                        >
                                          <a-input
                                            type="number"
                                            v-model:value="form.redirect_second"
                                            placeholder="Enter redirect second"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :lg="8"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="redirect_url"
                                          label="Redirect URL"
                                        >
                                          <a-input
                                            type="url"
                                            v-model:value="form.redirect_url"
                                            placeholder="Enter redirect url"
                                          />
                                        </a-form-item>
                                        <small
                                          >[firstname]&nbsp;&nbsp;[lastname]&nbsp;&nbsp;[email]&nbsp;&nbsp;[city]&nbsp;&nbsp;[phone]&nbsp;&nbsp;[zip]&nbsp;&nbsp;[address]</small
                                        >
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :lg="8"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="submit_url"
                                          label="Submit URL"
                                        >
                                          <a-input
                                            type="url"
                                            v-model:value="form.submit_url"
                                            placeholder="Enter submit url"
                                          />
                                        </a-form-item>
                                        <small
                                          >[firstname]&nbsp;&nbsp;[lastname]&nbsp;&nbsp;[email]&nbsp;&nbsp;[city]&nbsp;&nbsp;[phone]&nbsp;&nbsp;[zip]&nbsp;&nbsp;[address]</small
                                        >
                                      </a-col>
                                      <a-col  style="margin-bottom: 26px" :lg="12" :xs="24">
                                        <a-form-item
                                          name="campaign_image"
                                          label="Without Box Layout top logo"
                                        >
                                          <a-input
                                            type="file"
                                            id="campaign-file"
                                            accept="image/*"
                                            v-on:change="
                                              onImageChange(
                                                $event,
                                                'campaign_image'
                                              )
                                            "
                                          />
                                        </a-form-item>
                                        <div
                                          v-if="otherData.campaign_image_url"
                                          class="img-thumb-cls"
                                        >
                                          <img
                                            alt="Campaign Image"
                                            :src="otherData.campaign_image_url"
                                          />
                                        </div>
                                      </a-col>
                                      
                                      <a-col
                                        :lg="12"
                                        :xs="24"
                                        class="custom_select_selector"
                                      >
                                        <a-form-item
                                          name="theme_type"
                                          label="Theme Type"
                                        >
                                          <a-select
                                            :show-search="false"
                                            option-filter-prop="label"
                                            placeholder="Select Theme Type"
                                            v-model:value="form.theme_type"
                                          >
                                            <a-select-option
                                              v-for="theme of themeTypes"
                                              :key="theme.value"
                                              :value="theme.value"
                                              >{{
                                                theme.label
                                              }}</a-select-option
                                            >
                                          </a-select>
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="12" :xs="24">
                                        <a-form-item
                                          name="show_cookie_popup"
                                          label="Show Cookie Popup"
                                        >
                                          <a-switch
                                            v-model:checked="
                                              form.show_cookie_popup
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="12" :xs="24">
                                        <a-form-item
                                          name="close_campaign"
                                          label="Close Campaign"
                                        >
                                          <a-switch
                                            v-model:checked="
                                              form.close_campaign
                                            "
                                            @change="showCloseDetail"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col v-show="showCloseBlock" :lg="24" :xs="24" style="margin-top: 20px">
                                          <sdHeading as="h4">Close Detail</sdHeading>
                                          <a-row :gutter="25" style="margin-bottom: 0;">
                                            <a-col :lg="24" :xs="24">
                                              <a-form-item name="close_redirect_url" label="Redirect URL">
                                                <a-input  v-model:value="form.close_redirect_url" placeholder="Enter Redirect URL" />
                                              </a-form-item>
                                            </a-col>
                                            <a-col :lg="24" :xs="24" style="margin-top: 15px;">
                                              <a-form-item name="close_message" label="Message">
                                                <a-textarea v-model:value="form.close_message" :rows="4" placeholder="Enter Message" />
                                              </a-form-item>
                                            </a-col>
                                        </a-row>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </Child>
                                <Child key="meta_details">
                                  <template #tab
                                    ><span>SEO & Domain</span></template
                                  >
                                  <div class="create-account-form">
                                    <a-row :gutter="15">
                                      <a-col :lg="12" :xs="24">
                                        <a-form-item
                                          name="meta_title"
                                          label="Meta Title"
                                        >
                                          <a-input
                                            placeholder="Enter meta title"
                                            v-model:value="form.meta_title"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="12" :xs="24">
                                        <a-form-item
                                          name="meta_image"
                                          label="Meta Image"
                                        >
                                          <a-input
                                            type="file"
                                            id="meta-file"
                                            accept="image/*"
                                            v-on:change="
                                              onImageChange(
                                                $event,
                                                'meta_image'
                                              )
                                            "
                                          />
                                        </a-form-item>
                                        <div
                                          v-if="otherData.meta_image_url"
                                          class="img-thumb-cls"
                                        >
                                          <img
                                            alt="Meta Image"
                                            :src="otherData.meta_image_url"
                                          />
                                        </div>
                                      </a-col>
                                      <a-col :lg="24">
                                        <a-form-item
                                          name="meta_description"
                                          label="Meta description"
                                        >
                                          <a-textarea
                                            v-model:value="
                                              form.meta_description
                                            "
                                            :rows="4"
                                            placeholder="Enter meta description"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="24" :xs="24">
                                        <a-form-item
                                          name="campaign_domain"
                                          label="Campaign Domain"
                                        >
                                          <a-input
                                            type="url"
                                            v-model:value="form.campaign_domain"
                                            placeholder="Enter campaign domain"
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </Child>
                                <Child key="power_by_and_terms">
                                  <template #tab
                                    ><span>Power by and terms</span></template
                                  >
                                  <div class="create-account-form">
                                    <sdHeading as="h4"
                                      >Power by details</sdHeading
                                    >
                                    <a-row :gutter="15">
                                      <a-col :lg="24">
                                        <a-form-item
                                          name="powered_by_text"
                                          label="Powered by text"
                                        >
                                          <a-input
                                            v-model:value="form.powered_by_text"
                                            placeholder="Enter powered by text"
                                          />
                                        </a-form-item>
                                        <a-form-item
                                          name="powered_by_link"
                                          label="Powered by link"
                                        >
                                          <a-input
                                            type="url"
                                            v-model:value="form.powered_by_link"
                                            placeholder="Enter powered by link"
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <sdHeading as="h4"
                                      >Terms and condition details</sdHeading
                                    >
                                    <a-row>
                                      <a-col :lg="24">
                                        <a-form-item
                                          name="popup_terms_condition_label"
                                          label="Terms & Conditions Label"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.popup_terms_condition_label
                                            "
                                            placeholder="Enter Enter terms & conditions label"
                                          />
                                        </a-form-item>
                                        <a-form-item
                                          name="popup_terms_condition_title"
                                          label="Terms & Conditions Title"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.popup_terms_condition_title
                                            "
                                            placeholder="Enter Enter terms & conditions title"
                                          />
                                        </a-form-item>
                                        <a-form-item
                                          name="popup_terms_condition_warning"
                                          label="Terms & Conditions Warning"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.popup_terms_condition_warning
                                            "
                                            placeholder="Enter Enter terms & conditions warning"
                                          />
                                        </a-form-item>
                                        <a-form-item
                                          name="popup_terms_condition_value"
                                          label="Terms & Conditions"
                                        >
                                          <ckeditor
                                            :editor="editor"
                                            v-model="
                                              form.popup_terms_condition_value
                                            "
                                            :config="editorConfig"
                                          ></ckeditor>
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </Child>
                                <Child key="winners_n_prizes">
                                  <template #tab
                                    ><span>Winners & Prizes</span></template
                                  >
                                  <div class="create-account-form">
                                    <slot name="winners-prizes"></slot>
                                    <sdHeading as="h4"
                                      >Success layout</sdHeading
                                    >
                                    <a-row :gutter="25">
                                      <a-col :lg="12" :xs="24">
                                        <a-form-item
                                          name="prize_name_color"
                                          label="Price name color"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_name_color
                                            "
                                            placeholder="Enter price name color"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="12" :xs="24">
                                        <a-form-item
                                          name="prize_name_font_size"
                                          label="Price name font size"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_name_font_size
                                            "
                                            placeholder="Enter price name font size"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="prize_description_color"
                                          label="Price description color"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_description_color
                                            "
                                            placeholder="Enter price description color"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="prize_name_description_size"
                                          label="Price value description size"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_description_font_size
                                            "
                                            placeholder="Enter price value description size"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="prize_discount_code_color"
                                          label="Discount code color"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_discount_code_color
                                            "
                                            placeholder="Enter discount code color"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="prize_discount_code_size"
                                          label="Discount code font size"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_discount_code_size
                                            "
                                            placeholder="Enter discount code font size"
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <sdHeading as="h4">Badge</sdHeading>
                                    <a-row :gutter="25">
                                      <a-col :lg="6" :xs="24">
                                        <a-form-item
                                          name="prize_badge_color"
                                          label="Background Color"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_badge_color
                                            "
                                            placeholder="Enter badge color"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="6" :xs="24">
                                        <a-form-item
                                          name="prize_badge_text_size"
                                          label="Badge text size"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_badge_text_size
                                            "
                                            placeholder="Enter badge text size"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="6" :xs="24">
                                        <a-form-item
                                          name="prize_badge_text_color"
                                          label="Text value color"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_badge_text_color
                                            "
                                            placeholder="Enter badge text color"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="6" :xs="24">
                                        <a-form-item
                                          name="prize_badge_text"
                                          label="Text"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_badge_text
                                            "
                                            placeholder="Enter badge text"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="prize_value_color"
                                          label="Price value color"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_value_color
                                            "
                                            placeholder="Enter price value color"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="prize_value_font_size"
                                          label="Price value font size"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.prize_value_font_size
                                            "
                                            placeholder="Enter price value font size"
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <a-row :gutter="15">
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="total_subscriber"
                                          label="Total Participant"
                                        >
                                          <a-input
                                            type="number"
                                            v-model:value="
                                              form.total_subscriber
                                            "
                                            placeholder="Enter total participant"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="winning_sender_name"
                                          label="Winning Sender Name"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.winning_sender_name
                                            "
                                            placeholder="Enter Sender Name"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="winning_sender_email"
                                          label="Winning Sender Email"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.winning_sender_email
                                            "
                                            placeholder="Enter Sender Email"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-form-item
                                          name="winning_subject"
                                          label="Winning Subject"
                                        >
                                          <a-input
                                            v-model:value="form.winning_subject"
                                            placeholder="Enter Subject"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="24">
                                        <a-form-item
                                          label="Winner Email"
                                          name="winner_email"
                                        >
                                          <ckeditor
                                            :editor="editor"
                                            v-model="form.winner_email"
                                            :config="editorConfig"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col class="winner_content">
                                        <p class="tag_class">#FIRST_NAME#</p>
                                        <p class="tag_class">#LAST_NAME#</p>
                                        <p class="tag_class">#CAMPAIGN_NAME#</p>
                                        <p class="tag_class">#DISCOUNT_URL#</p>
                                        <p class="tag_class">#DISCOUNT_CODE#</p>
                                        <p class="tag_class">#PRICE_VALUE#</p>
                                        <p class="tag_class">#PRICE_NAME#</p>
                                        <p class="tag_class">
                                          #PRICE_DESCRIPTION#
                                        </p>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 26px"
                                        :lg="12"
                                        :xs="24"
                                      >
                                        <a-checkbox
                                          name="winning_email"
                                          v-model:checked="form.winning_email"
                                        >
                                          <strong>Winning Email</strong>
                                        </a-checkbox>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </Child>
                                <Child key="front_scripts">
                                  <template #tab><span>Scipts</span></template>
                                  <div class="create-account-form">
                                    <div
                                      class="page-header-actions"
                                      style="margin-bottom: 10px"
                                    >
                                      <sdButton
                                        class="pull-right"
                                        @click="addScript"
                                        size="small"
                                        type="primary"
                                      >
                                        <sdFeatherIcons type="plus" size="14" />
                                        Add Script
                                      </sdButton>
                                    </div>
                                    <a-table
                                      rowKey="id"
                                      :columns="scriptfields"
                                      :dataSource="scriptList"
                                      :pagination="false"
                                    >
                                      <template #position="{ record }">
                                        <div v-if="record.position == 1">
                                          Header
                                        </div>
                                        <div v-else-if="record.position == 2">
                                          Body
                                        </div>
                                        <div v-else-if="record.position == 3">
                                          Footer
                                        </div>
                                      </template>
                                      <template #action="{ record }">
                                        <div class="table-actions">
                                          <sdButton
                                            @click="onServiceupdate(record)"
                                            class="btn-icon"
                                            shape="circle"
                                          >
                                            <sdFeatherIcons
                                              type="edit"
                                              size="16"
                                            />
                                          </sdButton>
                                          <sdButton
                                            @click="
                                              onServiceRemove(record.encrypt_id)
                                            "
                                            class="btn-icon"
                                            shape="circle"
                                          >
                                            <sdFeatherIcons
                                              type="trash-2"
                                              size="16"
                                            />
                                          </sdButton>
                                        </div>
                                      </template>
                                    </a-table>
                                  </div>
                                </Child>
                              </TabBasic>
                            </BasicFormWrapper>
                          </template>
                          <template #layout>
                            <BasicFormWrapper class="basic-form-inner">
                              <TabBasic
                                v-model:activeKey="layoutActiveKey"
                                tabPosition="top"
                              >
                                <Child key="layout_details">
                                  <template #tab
                                    ><span>Design & layout</span></template
                                  >
                                  <div class="create-account-form">
                                    <a-row
                                      :gutter="15"
                                      style="margin-bottom: 0"
                                    >
                                      <a-col :lg="12" :xs="24">
                                        <sdHeading as="h4">Step 1</sdHeading>
                                        <sdHeading as="h5"
                                          >1. Headlines</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="headline_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter headline color"
                                                v-model:value="
                                                  form.headline_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="headline_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter headline font size"
                                                v-model:value="
                                                  form.headline_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5">2. Texts</sdHeading>
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="text_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter text color"
                                                v-model:value="form.text_color"
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="text_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter text font size"
                                                v-model:value="
                                                  form.text_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >3. Buttons</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="button_color"
                                              label="Backgroun Color"
                                            >
                                              <a-input
                                                placeholder="Enter button color"
                                                v-model:value="
                                                  form.button_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="button_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter button font size"
                                                v-model:value="
                                                  form.button_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="button_font_color"
                                              label="Font Color"
                                            >
                                              <a-input
                                                placeholder="Enter button font color"
                                                v-model:value="
                                                  form.button_font_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >4. Background</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="step_background_color"
                                              label="Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter background color"
                                                v-model:value="
                                                  form.step_background_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="step_opacity_level"
                                              label="Opacity Level"
                                            >
                                              <a-input
                                                placeholder="Enter Opacity"
                                                v-model:value="
                                                  form.step_opacity_level
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                      </a-col>
                                      <a-col :lg="12" :xs="24">
                                        <sdHeading as="h4">Step 2</sdHeading>
                                        <sdHeading as="h5"
                                          >1. Headlines</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="step2_headline_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter headline color"
                                                v-model:value="
                                                  form.step2_headline_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="step2_headline_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter headline font size"
                                                v-model:value="
                                                  form.step2_headline_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5">2. Texts</sdHeading>
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="step2_text_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter text color"
                                                v-model:value="
                                                  form.step2_text_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="step2_text_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter text font size"
                                                v-model:value="
                                                  form.step2_text_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >3. Buttons</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="step2_button_color"
                                              label="Backgroun Color"
                                            >
                                              <a-input
                                                placeholder="Enter button color"
                                                v-model:value="
                                                  form.step2_button_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="step2_button_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter button font size"
                                                v-model:value="
                                                  form.step2_button_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="step2_button_font_color"
                                              label="Font Color"
                                            >
                                              <a-input
                                                placeholder="Enter button font color"
                                                v-model:value="
                                                  form.step2_button_font_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >4. Background</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="step2_background_color"
                                              label="Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter background color"
                                                v-model:value="
                                                  form.step2_background_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="step2_opacity_level"
                                              label="Opacity Level"
                                            >
                                              <a-input
                                                placeholder="Enter Opacity"
                                                v-model:value="
                                                  form.step2_opacity_level
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                      </a-col>
                                    </a-row>
                                  </div>
                                  <div class="create-account-form">
                                    <a-row
                                      :gutter="15"
                                      style="margin-bottom: 0"
                                    >
                                      <a-col :lg="8" :xs="24">
                                        <sdHeading as="h4">Popup</sdHeading>
                                        <sdHeading as="h5"
                                          >1. Headlines</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="popup_headline_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter headline color"
                                                v-model:value="
                                                  form.popup_headline_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="popup_headline_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter headline font size"
                                                v-model:value="
                                                  form.popup_headline_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5">2. Texts</sdHeading>
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="popup_text_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter text color"
                                                v-model:value="
                                                  form.popup_text_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="popup_text_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter text font size"
                                                v-model:value="
                                                  form.popup_text_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >3. Buttons</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="popup_button_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter button color"
                                                v-model:value="
                                                  form.popup_button_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="popup_button_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter button font size"
                                                v-model:value="
                                                  form.popup_button_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="popup_button_font_color"
                                              label="Font Color"
                                            >
                                              <a-input
                                                placeholder="Enter button font color"
                                                v-model:value="
                                                  form.popup_button_font_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >4. Background</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="popup_background_color"
                                              label="Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter background color"
                                                v-model:value="
                                                  form.popup_background_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="popup_opacity_level"
                                              label="Opacity Level"
                                            >
                                              <a-input
                                                placeholder="Enter Opacity"
                                                v-model:value="
                                                  form.popup_opacity_level
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="popup_close_icon_color"
                                              label="Close button Color"
                                            >
                                              <a-input
                                                placeholder="Enter close button color"
                                                v-model:value="
                                                  form.popup_close_icon_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >5. Form Field</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="popup_input_border_color"
                                              label="Border"
                                            >
                                              <a-input
                                                placeholder="Enter Border"
                                                v-model:value="
                                                  form.popup_input_border_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="popup_input_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter font size"
                                                v-model:value="
                                                  form.popup_input_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                      </a-col>
                                      <a-col :lg="8" :xs="24">
                                        <sdHeading as="h4"
                                          >Winner popup</sdHeading
                                        >
                                        <sdHeading as="h5"
                                          >1. Headlines</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="winner_headline_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter headline color"
                                                v-model:value="
                                                  form.winner_headline_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="winner_headline_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter headline font size"
                                                v-model:value="
                                                  form.winner_headline_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5">2. Texts</sdHeading>
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="winner_text_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter text color"
                                                v-model:value="
                                                  form.winner_text_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="winner_text_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter text font size"
                                                v-model:value="
                                                  form.winner_text_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >3. Buttons</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="winner_button_color"
                                              label="Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter button color"
                                                v-model:value="
                                                  form.winner_button_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="winner_button_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter button font size"
                                                v-model:value="
                                                  form.winner_button_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="winner_button_font_color"
                                              label="Font Color"
                                            >
                                              <a-input
                                                placeholder="Enter button font color"
                                                v-model:value="
                                                  form.winner_button_font_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >4. Background</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="winner_background_color"
                                              label="Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter background color"
                                                v-model:value="
                                                  form.winner_background_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="winner_opacity_level"
                                              label="Opacity Level"
                                            >
                                              <a-input
                                                placeholder="Enter Opacity"
                                                v-model:value="
                                                  form.winner_opacity_level
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                      </a-col>
                                      <a-col :lg="8" :xs="24">
                                        <sdHeading as="h4"
                                          >Loser popup</sdHeading
                                        >
                                        <sdHeading as="h5"
                                          >1. Headlines</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="loser_headline_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter headline color"
                                                v-model:value="
                                                  form.loser_headline_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="loser_headline_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter headline font size"
                                                v-model:value="
                                                  form.loser_headline_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5">2. Texts</sdHeading>
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="loser_text_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter text color"
                                                v-model:value="
                                                  form.loser_text_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="loser_text_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter text font size"
                                                v-model:value="
                                                  form.loser_text_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >3. Buttons</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="loser_button_color"
                                              label="Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter button color"
                                                v-model:value="
                                                  form.loser_button_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="loser_button_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter button font size"
                                                v-model:value="
                                                  form.loser_button_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="loser_button_font_color"
                                              label="Font Color"
                                            >
                                              <a-input
                                                placeholder="Enter button font color"
                                                v-model:value="
                                                  form.loser_button_font_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >4. Background</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="loser_background_color"
                                              label="Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter background color"
                                                v-model:value="
                                                  form.loser_background_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="loser_opacity_level"
                                              label="Opacity Level"
                                            >
                                              <a-input
                                                placeholder="Enter Opacity"
                                                v-model:value="
                                                  form.loser_opacity_level
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                      </a-col>
                                    </a-row>
                                  </div>
                                  <div class="create-account-form">
                                    <a-row
                                      :gutter="15"
                                      style="margin-bottom: 0"
                                    >
                                      <a-col :lg="24" :xs="24">
                                        <sdHeading as="h4"
                                          >Terms & Condition</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="tc_font_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter font color"
                                                v-model:value="
                                                  form.tc_font_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="tc_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter font size"
                                                v-model:value="
                                                  form.tc_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                      </a-col>
                                    </a-row>
                                  </div>
                                  <div class="create-account-form">
                                    <a-row
                                      :gutter="15"
                                      style="margin-bottom: 0"
                                    >
                                      <a-col :lg="24" :xs="24">
                                        <sdHeading as="h4"
                                          >Custom CSS</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="24">
                                            <a-form-item name="custom_css">
                                              <a-textarea
                                                v-model:value="form.custom_css"
                                                :rows="5"
                                                placeholder="Custom CSS"
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                      </a-col>
                                    </a-row>
                                  </div>
                                  <div class="create-account-form">
                                    <a-row :gutter="15">
                                      <a-col :lg="24" :xs="24">
                                        <sdHeading as="h4"
                                          >Prize popup</sdHeading
                                        >
                                        <sdHeading as="h5"
                                          >1. Headlines</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="12" :lg="12">
                                            <a-form-item
                                              name="price_headline_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter headline color"
                                                v-model:value="
                                                  form.price_headline_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="price_headline_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter headline font size"
                                                v-model:value="
                                                  form.price_headline_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5">2. Texts</sdHeading>
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="price_text_color"
                                              label="Color"
                                            >
                                              <a-input
                                                placeholder="Enter text color"
                                                v-model:value="
                                                  form.price_text_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="price_text_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter text font size"
                                                v-model:value="
                                                  form.price_text_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="12"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_description_text_color"
                                              label="Description Color"
                                            >
                                              <a-input
                                                placeholder="Enter text color"
                                                v-model:value="
                                                  form.price_description_text_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="12"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_description_font_size"
                                              label="Description Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter text font size"
                                                v-model:value="
                                                  form.price_description_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="5"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_tag_title_color"
                                              label="Prize Title Color"
                                            >
                                              <a-input
                                                placeholder="Enter Title color"
                                                v-model:value="
                                                  form.price_tag_title_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="5"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_tag_title_font_size"
                                              label="Prize Title Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter Title Font Size"
                                                v-model:value="
                                                  form.price_tag_title_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="5"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_tag_text_color"
                                              label="Prize Color"
                                            >
                                              <a-input
                                                placeholder="Enter text color"
                                                v-model:value="
                                                  form.price_tag_text_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="4"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_tag_font_size"
                                              label="Prize Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter text font size"
                                                v-model:value="
                                                  form.price_tag_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="5"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_tag_background_color"
                                              label="Prize Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter text background color"
                                                v-model:value="
                                                  form.price_tag_background_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >3. Prize Button</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="price_button_color"
                                              label="Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter button color"
                                                v-model:value="
                                                  form.price_button_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="price_button_font_size"
                                              label="Font Size"
                                            >
                                              <a-input
                                                placeholder="Enter button font size"
                                                v-model:value="
                                                  form.price_button_font_size
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="8">
                                            <a-form-item
                                              name="price_button_font_color"
                                              label="Font Color"
                                            >
                                              <a-input
                                                placeholder="Enter button font color"
                                                v-model:value="
                                                  form.price_button_font_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="8"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_button_height"
                                              label="Height"
                                            >
                                              <a-input
                                                placeholder="Enter button height"
                                                v-model:value="
                                                  form.price_button_height
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="8"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_button_width"
                                              label="Width"
                                            >
                                              <a-input
                                                placeholder="Enter button width"
                                                v-model:value="
                                                  form.price_button_width
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col
                                            :xs="24"
                                            :lg="8"
                                            style="margin-top: 10px"
                                          >
                                            <a-form-item
                                              name="price_button_right_position"
                                              label="Right Position"
                                            >
                                              <a-input
                                                placeholder="Enter button right position"
                                                v-model:value="
                                                  form.price_button_right_position
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                        <sdHeading as="h5"
                                          >4. Popup Background</sdHeading
                                        >
                                        <a-row :gutter="15">
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="price_background_color"
                                              label="Background Color"
                                            >
                                              <a-input
                                                placeholder="Enter background color"
                                                v-model:value="
                                                  form.price_background_color
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                          <a-col :xs="24" :lg="12">
                                            <a-form-item
                                              name="price_opacity_level"
                                              label="Opacity Level"
                                            >
                                              <a-input
                                                placeholder="Enter Opacity"
                                                v-model:value="
                                                  form.price_opacity_level
                                                "
                                              />
                                            </a-form-item>
                                          </a-col>
                                        </a-row>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </Child>
                                <Child key="content_details">
                                  <template #tab
                                    ><span>Content Settings</span></template
                                  >
                                  <slot name="content-setting"></slot>
                                </Child>
                                <Child key="participant_details">
                                  <template #tab
                                    ><span>Signup form</span></template
                                  >
                                  <div class="create-account-form">
                                    <a-row :gutter="15">
                                      <a-col :lg="8" :xs="24">
                                        <a-form-item
                                          name="popup_title"
                                          label="Popup Title"
                                        >
                                          <a-input
                                            v-model:value="form.popup_title"
                                            placeholder="Enter popup title"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="8" :xs="24">
                                        <a-form-item
                                          name="popup_sub_text"
                                          label="Popup Sub Text"
                                        >
                                          <a-input
                                            v-model:value="form.popup_sub_text"
                                            placeholder="Enter popup sub text"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :lg="8" :xs="24">
                                        <a-form-item
                                          name="popup_button_text"
                                          label="Submit button text"
                                        >
                                          <a-input
                                            v-model:value="
                                              form.popup_button_text
                                            "
                                            placeholder="Enter button text"
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <a-row
                                      type="flex"
                                      align="middle"
                                      :gutter="15"
                                    >
                                      <a-col :md="6" :xs="6">
                                        <a-checkbox
                                          name="first_name_check"
                                          v-model:checked="
                                            form.popup_first_name_flag
                                          "
                                        >
                                          <strong>First name:</strong>
                                        </a-checkbox>
                                      </a-col>
                                      <a-col :md="12" :xs="12">
                                        <a-form-item
                                          name="popup_first_name_place_holder"
                                        >
                                          <a-input
                                            :disabled="
                                              !form.popup_first_name_flag
                                            "
                                            placeholder="Enter first name placeholder"
                                            v-model:value="
                                              form.popup_first_name_place_holder
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :md="6" :xs="6">
                                        <a-form-item
                                          name="popup_first_name_order"
                                        >
                                          <a-input
                                            type="number"
                                            placeholder="Order number"
                                            :disabled="
                                              !form.popup_first_name_flag
                                            "
                                            v-model:value="
                                              form.popup_first_name_order
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <a-row
                                      type="flex"
                                      align="middle"
                                      :gutter="15"
                                    >
                                      <a-col :md="6" :xs="6">
                                        <a-checkbox
                                          name="last_name_check"
                                          v-model:checked="
                                            form.popup_last_name_flag
                                          "
                                        >
                                          <strong>Last name:</strong>
                                        </a-checkbox>
                                      </a-col>
                                      <a-col :md="12" :xs="12">
                                        <a-form-item
                                          name="popup_last_name_place_holder"
                                        >
                                          <a-input
                                            :disabled="
                                              !form.popup_last_name_flag
                                            "
                                            placeholder="Enter last name placeholder"
                                            v-model:value="
                                              form.popup_last_name_place_holder
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :md="6" :xs="6">
                                        <a-form-item
                                          name="popup_last_name_order"
                                        >
                                          <a-input
                                            type="number"
                                            :disabled="
                                              !form.popup_last_name_flag
                                            "
                                            placeholder="Order number"
                                            v-model:value="
                                              form.popup_last_name_order
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <a-row
                                      type="flex"
                                      align="middle"
                                      :gutter="15"
                                    >
                                      <a-col :md="6" :xs="6">
                                        <a-checkbox
                                          name="popup_address_flag"
                                          v-model:checked="
                                            form.popup_address_flag
                                          "
                                        >
                                          <strong>Address:</strong>
                                        </a-checkbox>
                                      </a-col>
                                      <a-col :md="12" :xs="12">
                                        <a-form-item
                                          name="popup_address_place_holder"
                                        >
                                          <a-input
                                            :disabled="!form.popup_address_flag"
                                            placeholder="Enter address placeholder"
                                            v-model:value="
                                              form.popup_address_place_holder
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :md="6" :xs="6">
                                        <a-form-item name="popup_address_order">
                                          <a-input
                                            type="number"
                                            :disabled="!form.popup_address_flag"
                                            placeholder="Order number"
                                            v-model:value="
                                              form.popup_address_order
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <a-row
                                      type="flex"
                                      align="middle"
                                      :gutter="15"
                                    >
                                      <a-col :md="6" :xs="6">
                                        <a-checkbox
                                          name="popup_email_flag"
                                          v-model:checked="
                                            form.popup_email_flag
                                          "
                                        >
                                          <strong>Email:</strong>
                                        </a-checkbox>
                                      </a-col>
                                      <a-col :md="12" :xs="12">
                                        <a-form-item
                                          name="popup_email_place_holder"
                                        >
                                          <a-input
                                            :disabled="!form.popup_email_flag"
                                            placeholder="Enter email placeholder"
                                            v-model:value="
                                              form.popup_email_place_holder
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :md="6" :xs="6">
                                        <a-form-item name="popup_email_order">
                                          <a-input
                                            type="number"
                                            :disabled="!form.popup_email_flag"
                                            placeholder="Order number"
                                            v-model:value="
                                              form.popup_email_order
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <a-row
                                      type="flex"
                                      align="middle"
                                      :gutter="15"
                                    >
                                      <a-col :md="6" :xs="6">
                                        <a-checkbox
                                          name="popup_phone_flag"
                                          v-model:checked="
                                            form.popup_phone_flag
                                          "
                                        >
                                          <strong>Phone:</strong>
                                        </a-checkbox>
                                      </a-col>
                                      <a-col :md="12" :xs="12">
                                        <a-form-item
                                          name="popup_phone_place_holder"
                                        >
                                          <a-input
                                            :disabled="!form.popup_phone_flag"
                                            placeholder="Enter phone placeholder"
                                            v-model:value="
                                              form.popup_phone_place_holder
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :md="6" :xs="6">
                                        <a-form-item name="popup_phone_order">
                                          <a-input
                                            type="number"
                                            :disabled="!form.popup_phone_flag"
                                            placeholder="Order number"
                                            v-model:value="
                                              form.popup_phone_order
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <a-row
                                      type="flex"
                                      align="middle"
                                      :gutter="15"
                                    >
                                      <a-col :md="6" :xs="6">
                                        <a-checkbox
                                          name="popup_zipcode_flag"
                                          v-model:checked="
                                            form.popup_zipcode_flag
                                          "
                                        >
                                          <strong>Zipcode:</strong>
                                        </a-checkbox>
                                      </a-col>
                                      <a-col :md="12" :xs="12">
                                        <a-form-item
                                          name="popup_zipcode_place_holder"
                                        >
                                          <a-input
                                            :disabled="!form.popup_zipcode_flag"
                                            placeholder="Enter zipcode placeholder"
                                            v-model:value="
                                              form.popup_zipcode_place_holder
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :md="6" :xs="6">
                                        <a-form-item name="popup_zipcode_order">
                                          <a-input
                                            type="number"
                                            :disabled="!form.popup_zipcode_flag"
                                            placeholder="Order number"
                                            v-model:value="
                                              form.popup_zipcode_order
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                    <a-row
                                      type="flex"
                                      align="middle"
                                      :gutter="15"
                                    >
                                      <a-col :md="6" :xs="6">
                                        <a-checkbox
                                          name="popup_city_flag"
                                          v-model:checked="form.popup_city_flag"
                                        >
                                          <strong>City:</strong>
                                        </a-checkbox>
                                      </a-col>
                                      <a-col :md="12" :xs="12">
                                        <a-form-item
                                          name="popup_city_place_holder"
                                        >
                                          <a-input
                                            :disabled="!form.popup_city_flag"
                                            placeholder="Enter city placeholder"
                                            v-model:value="
                                              form.popup_city_place_holder
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :md="6" :xs="6">
                                        <a-form-item name="popup_city_order">
                                          <a-input
                                            type="number"
                                            placeholder="Order number"
                                            :disabled="!form.popup_city_flag"
                                            v-model:value="
                                              form.popup_city_order
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </Child>
                                <Child key="teaser_settings">
                                  <template #tab
                                    ><span>Teaser Settings</span></template
                                  >
                                  <div class="create-account-form">
                                    <sdHeading as="h4">Teaser Button</sdHeading>
                                    <a-row
                                      :gutter="15"
                                      style="margin-bottom: 0"
                                    >
                                      <a-col :xs="24" :lg="6">
                                        <a-form-item
                                          name="script_button_text"
                                          label="Text"
                                        >
                                          <a-input
                                            placeholder="Enter Button text"
                                            v-model:value="
                                              form.script_button_text
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :xs="24" :lg="6">
                                        <a-form-item
                                          name="script_button_background_color"
                                          label="Background Color"
                                        >
                                          <a-input
                                            placeholder="Enter button color in hexcode"
                                            v-model:value="
                                              form.script_button_background_color
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :xs="24" :lg="6">
                                        <a-form-item
                                          name="script_button_text_color"
                                          label="Text Color"
                                        >
                                          <a-input
                                            placeholder="Enter color in hexcode"
                                            v-model:value="
                                              form.script_button_text_color
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col :xs="24" :lg="6">
                                        <a-form-item
                                          name="script_button_text_size"
                                          label="Text size (PX)"
                                        >
                                          <a-input
                                            placeholder="Enter text size in pixel"
                                            v-model:value="
                                              form.script_button_text_size
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 20px"
                                        :xs="24"
                                        :lg="8"
                                      >
                                        <a-form-item
                                          name="script_button_border_radius"
                                          label="Border radius (PX)"
                                        >
                                          <a-input
                                            placeholder="Enter border radius in pixel"
                                            v-model:value="
                                              form.script_button_border_radius
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 20px"
                                        :xs="24"
                                        :lg="8"
                                      >
                                        <a-form-item
                                          name="script_button_height"
                                          label="Height (PX)"
                                        >
                                          <a-input
                                            placeholder="Enter height in pixel"
                                            v-model:value="
                                              form.script_button_height
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 20px"
                                        :xs="24"
                                        :lg="8"
                                      >
                                        <a-form-item
                                          name="script_button_width"
                                          label="Width (PX)"
                                        >
                                          <a-input
                                            placeholder="Enter width in pixel"
                                            v-model:value="
                                              form.script_button_width
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 20px"
                                        :xs="24"
                                        :lg="12"
                                      >
                                        <a-form-item
                                          name="script_button_position_top"
                                          label="Position top (%)"
                                        >
                                          <a-input
                                            placeholder="Enter button position from top in %"
                                            v-model:value="
                                              form.script_button_position_top
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-top: 20px"
                                        :xs="24"
                                        :lg="12"
                                      >
                                        <a-form-item
                                          name="script_button_position_left"
                                          label="Position left (%)"
                                        >
                                          <a-input
                                            placeholder="Enter button position from left in %"
                                            v-model:value="
                                              form.script_button_position_left
                                            "
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                  </div>
                                  <div
                                    style="margin-top: 20px"
                                    class="create-account-form"
                                  >
                                    <sdHeading as="h4"
                                      >Display Condition</sdHeading
                                    >
                                    <a-row
                                      :gutter="15"
                                      style="margin-bottom: 0"
                                    >
                                      <a-col :xs="24" :lg="12">
                                        <a-form-item label="Countries">
                                          <a-select
                                            mode="multiple"
                                            placeholder="Select country for teaser button"
                                            v-model:value="
                                              otherData.scriptSelectedCountry
                                            "
                                            :default-value="
                                              otherData.scriptselectedCountry
                                            "
                                          >
                                            <a-select-option
                                              v-for="country of countries"
                                              :key="country.value"
                                              :value="country.value"
                                              >{{
                                                country.label
                                              }}</a-select-option
                                            >
                                          </a-select>
                                          <small
                                            >Leave blank for all country</small
                                          >
                                        </a-form-item>
                                      </a-col>
                                      <a-col :xs="24" :lg="12">
                                        <a-form-item label="Devices">
                                          <a-select
                                            mode="multiple"
                                            placeholder="Select device for teaser button"
                                            v-model:value="
                                              otherData.scriptSelectedDevice
                                            "
                                            :default-value="
                                              otherData.scriptSelectedDevice
                                            "
                                          >
                                            <a-select-option
                                              v-for="d of devices"
                                              :key="d.value"
                                              :value="d.value"
                                              >{{ d.label }}</a-select-option
                                            >
                                          </a-select>
                                          <small
                                            >Leave blank for all device</small
                                          >
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                  </div>
                                  <div
                                    style="margin-top: 20px"
                                    class="create-account-form"
                                  >
                                    <a-row
                                      :gutter="15"
                                      style="margin-bottom: 10"
                                    >
                                      <a-col :xs="24" :lg="24">
                                        <a-form-item
                                          name="embeded_script"
                                          label="Script"
                                        >
                                          <a-textarea
                                            v-model:value="form.embeded_script"
                                            :rows="4"
                                            placeholder="Script"
                                            disabled
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </Child>
                              </TabBasic>
                            </BasicFormWrapper>
                          </template>
                          <template #display-conditions>
                            <BasicFormWrapper class="basic-form-inner">
                              <TabBasic
                                v-model:activeKey="displayActiveKey"
                                tabPosition="top"
                              >
                                <Child key="display_condition">
                                  <template #tab
                                    ><span>Access Conditions</span></template
                                  >
                                  <div class="create-account-form">
                                    <a-row :gutter="15">
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :xs="24"
                                        :lg="12"
                                      >
                                        <a-form-item label="Countries">
                                          <a-select
                                            mode="multiple"
                                            placeholder="Select countries"
                                            v-model:value="
                                              otherData.selectedCountry
                                            "
                                            :default-value="
                                              otherData.selectedCountry
                                            "
                                          >
                                            <a-select-option
                                              v-for="country of countries"
                                              :key="country.value"
                                              :value="country.value"
                                              >{{
                                                country.label
                                              }}</a-select-option
                                            >
                                          </a-select>
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :xs="24"
                                        :lg="12"
                                      >
                                        <a-form-item label="OS">
                                          <a-select
                                            mode="multiple"
                                            placeholder="Select OS"
                                            v-model:value="otherData.selectedOs"
                                            :default-value="
                                              otherData.selectedOs
                                            "
                                          >
                                            <a-select-option
                                              v-for="o of os"
                                              :key="o.value"
                                              :value="o.value"
                                              >{{ o.label }}</a-select-option
                                            >
                                          </a-select>
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :xs="24"
                                        :lg="12"
                                      >
                                        <a-form-item label="Devices">
                                          <a-select
                                            mode="multiple"
                                            placeholder="Select devices"
                                            v-model:value="
                                              otherData.selectedDevice
                                            "
                                            :default-value="
                                              otherData.selectedDevice
                                            "
                                          >
                                            <a-select-option
                                              v-for="d of devices"
                                              :key="d.value"
                                              :value="d.value"
                                              >{{ d.label }}</a-select-option
                                            >
                                          </a-select>
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        style="margin-bottom: 26px"
                                        :xs="24"
                                        :lg="12"
                                      >
                                        <a-form-item label="Browsers">
                                          <a-select
                                            mode="multiple"
                                            placeholder="Select browsers"
                                            v-model:value="
                                              otherData.selectedBrowser
                                            "
                                            :default-value="
                                              otherData.selectedBrowser
                                            "
                                          >
                                            <a-select-option
                                              v-for="b of browsers"
                                              :key="b.value"
                                              :value="b.value"
                                              >{{ b.label }}</a-select-option
                                            >
                                          </a-select>
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </Child>
                                <Child key="display_message">
                                  <template #tab
                                    ><span>Display Message</span></template
                                  >

                                  <div class="create-account-form">
                                    <a-col
                                      style="margin-bottom: 26px"
                                      :xs="24"
                                      :lg="12"
                                    >
                                      <a-radio-group
                                        v-model:value="form.not_found"
                                      >
                                        <a-radio :value="1">URL</a-radio>
                                        <a-radio :value="2">Message</a-radio>
                                      </a-radio-group>
                                    </a-col>
                                    <a-row :gutter="15">
                                      <a-col
                                        v-if="form.not_found == 1"
                                        :xs="24"
                                        :lg="24"
                                      >
                                        <a-form-item
                                          name="not_found_url"
                                          label="Not Found URL"
                                        >
                                          <a-input
                                            type="url"
                                            placeholder="Enter not found url"
                                            v-model:value="form.not_found_url"
                                          />
                                        </a-form-item>
                                      </a-col>
                                      <a-col
                                        v-if="form.not_found == 2"
                                        :xs="24"
                                        :lg="24"
                                      >
                                        <a-form-item
                                          label="Not found message"
                                          name="not_found_message"
                                        >
                                          <ckeditor
                                            :editor="editor"
                                            v-model="form.not_found_message"
                                            :config="editorConfig"
                                          />
                                        </a-form-item>
                                      </a-col>
                                    </a-row>
                                  </div>
                                </Child>
                              </TabBasic>
                            </BasicFormWrapper>
                          </template>
                          <template #confirmation>
                            <a-row justify="center" :style="{ width: '100%' }">
                              <a-col :xl="20" :xs="24">
                                <div class="checkout-successful">
                                  <sdCards
                                    headless
                                    :bodyStyle="{
                                      backgroundColor: '#F8F9FB',
                                      borderRadius: '20px',
                                    }"
                                  >
                                    <sdCards headless>
                                      <span class="icon-success">
                                        <sdFeatherIcons type="check" />
                                      </span>
                                      <sdHeading as="h3"
                                        >Campaign Launched</sdHeading
                                      >
                                      <p>
                                        Congrats, your campaign is launched, you
                                        can preview your campaign here
                                      </p>
                                      <div class="preview-box">
                                        <a @click="openFrontPage()">{{
                                          previewLink
                                        }}</a>
                                      </div>
                                      <div class="card-bottom-button-class">
                                        <sdButton
                                          @click="stepView.prev()"
                                          type="light"
                                        >
                                          Back
                                        </sdButton>
                                      </div>
                                    </sdCards>
                                  </sdCards>
                                </div>
                              </a-col>
                            </a-row>
                          </template>
                        </Steps>
                      </WizardView>
                    </WizardWrapper>
                  </div>
                </a-col>
              </a-row>
            </sdCards>
          </WizardBlock>
        </a-form>
      </a-col>
    </a-row>
    <a-modal
      v-model:visible="confirmModalVisible"
      title="Campaign confirmation"
      :confirm-loading="isUploading"
      :centered="true"
      okText="Confirm"
      cancelText="Back"
      @ok="handleModalOk"
      :ok-button-props="okButtonProps"
    >
      <p>Are you sure you want complete this campaign?</p>
    </a-modal>
    <a-modal
      v-model:visible="addScriptVisible"
      :title="`Script`"
      :centered="true"
      ok-text="Save"
      width="600px"
      cancel-text="Cancel"
      @cancel="cancelScriptModal"
      @ok="handleScriptAddModal"
      :ok-button-props="okButtonProps"
    >
      <a-form :hideRequiredMark="false" ref="formRef">
        <a-form-item name="script_name" label="Name">
          <a-input v-model:value="script_name" placeholder="Name" />
        </a-form-item>
        <a-form-item name="script_position" label="Position">
          <a-select
            placeholder="Select position"
            v-model:value="script_position"
          >
            <a-select-option :value="1">Header</a-select-option>
            <a-select-option :value="2">Body</a-select-option>
            <a-select-option :value="3">Footer</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="script" label="Script">
          <a-textarea v-model:value="script" :rows="4" placeholder="Script" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="deleteScriptConfirmVisible"
      title="Script remove confirmation"
      :confirm-loading="isDeleting"
      :centered="true"
      ok-text="Remove"
      cancel-text="Cancel"
      @ok="handleScriptDeleteModal"
      :ok-button-props="okButtonProps"
    >
      <p>Are you sure you want to delete this script?</p>
    </a-modal>
  </Main>
</template>
<script>
import _ from "lodash";
import VueTypes from "vue-types";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Steps from "@/components/steps/steps";
import { Main, BasicFormWrapper } from "@/view/styled";
import {
  Child,
  TabBasic,
  WizardView,
  WizardBlock,
  WizardWrapper,
} from "../style";
import { API } from "@/services/api";
import Notification from "@/services/Notification";
import { SystemData } from "@/services/helperFunctions";
import { theme } from "@/config/theme/themeVariables";

export default {
  name: "CampaignForm",
  props: {
    formData: VueTypes.object.def({}),
    initialData: VueTypes.object.def({}),
    otherFormData: VueTypes.object.def({}),
    isEditing: VueTypes.bool.def(false),
  },
  components: {
    Main,
    Child,
    Steps,
    TabBasic,
    WizardView,
    WizardBlock,
    WizardWrapper,
    BasicFormWrapper,
  },
  computed: {
    gameData: function () {
      return this.$store.getters.gameData;
    },
    themeData: function () {
      return this.$store.getters.themeData;
    },
    themeTypes: function () {
      return [
        {
          label: "Row Layout",
          value: "verticle",
        },
        {
          label: "Column Layout",
          value: "horizontal",
        },
        {
          label: "Without Box Layout",
          value: "without_card",
        },
      ];
    },
    countries: function () {
      return this.$store.getters.countries;
    },
    stepView: function () {
      return this.$refs.stepView;
    },
    formRef: function () {
      return this.$refs.formRef;
    },
  },
  data() {
    return {
      okButtonProps: {
        style: {
          backgroundColor: theme["primary-color"],
          borderWidth: 0,
        },
      },
      isDeleting: false,
      settingsActiveKey: "campaign_details",
      layoutActiveKey: "layout_details",
      displayActiveKey: "display_condition",
      status: "process",
      currentStep: 0,
      isFinished: false,
      isVertical: true,
      editor: ClassicEditor,
      editorConfig: {},
      isUploading: false,
      confirmModalVisible: false,
      addScriptVisible: false,
      deleteScriptConfirmVisible: false,
      script_name: "",
      script_position: "",
      script: "",
      showPrevBtn: false,
      showCloseBlock: false,
      form: {
        name: "",
        slug: "",
        show_cookie_popup: false,
        close_campaign: false,
        theme_type: null,
        campaign_image: null,
        total_subscriber: "",
        submit_url: "",
        campaign_domain: "",
        id: "",
        powered_by_text: "",
        powered_by_link: "",
        redirect_url: "",
        redirect_second: "",
        game_id: null,
        theme_id: null,
        meta_title: "",
        meta_image: null,
        meta_description: "",
        winner_email: "",
        winning_email: true,
        popup_title: "",
        popup_sub_text: "",
        popup_button_text: "",
        popup_first_name_flag: true,
        popup_first_name_place_holder: "",
        popup_first_name_order: "",
        popup_last_name_flag: true,
        popup_last_name_place_holder: "",
        popup_last_name_order: "",
        popup_address_flag: true,
        popup_address_place_holder: "",
        popup_address_order: "",
        popup_email_flag: true,
        popup_email_place_holder: "",
        popup_email_order: "",
        popup_city_flag: true,
        popup_city_place_holder: "",
        popup_city_order: "",
        popup_phone_flag: true,
        popup_phone_place_holder: "",
        popup_phone_order: "",
        popup_zipcode_flag: true,
        popup_zipcode_place_holder: "",
        popup_zipcode_order: "",
        popup_terms_condition_label: "",
        popup_terms_condition_title: "",
        popup_terms_condition_warning: "",
        popup_terms_condition_value: "",
        not_found: 1,
        not_found_url: "",
        not_found_message: "",
        headline_color: "",
        step2_headline_color: "",
        headline_font_size: "",
        step2_headline_font_size: "",
        text_color: "",
        step2_text_color: "",
        text_font_size: "",
        step2_text_font_size: "",
        button_color: "",
        step2_button_color: "",
        button_font_size: "",
        step2_button_font_size: "",
        button_font_color: "",
        step2_button_font_color: "",
        header_script: "",
        body_script: "",
        footer_script: "",
        winning_sender_name: "",
        winning_sender_email: "",
        winning_subject: "",
        popup_opacity_level: "",
        popup_background_color: "",
        popup_headline_color: "",
        popup_headline_font_size: "",
        popup_button_font_size: "",
        popup_text_color: "",
        popup_text_font_size: "",
        popup_button_color: "",
        popup_button_font_color: "",
        step_background_color: "",
        step2_background_color: "",
        step_opacity_level: "",
        step2_opacity_level: "",
        popup_close_icon_color: "",
        popup_input_border_color: "",
        popup_input_font_size: "",
        winner_opacity_level: "",
        winner_background_color: "",
        winner_headline_color: "",
        winner_headline_font_size: "",
        winner_button_font_size: "",
        winner_text_color: "",
        winner_text_font_size: "",
        winner_button_color: "",
        winner_button_font_color: "",
        loser_opacity_level: "",
        loser_background_color: "",
        loser_headline_color: "",
        loser_headline_font_size: "",
        loser_button_font_size: "",
        loser_text_color: "",
        loser_text_font_size: "",
        loser_button_color: "",
        loser_button_font_color: "",
        price_opacity_level: "",
        price_background_color: "",
        price_headline_color: "",
        price_headline_font_size: "",
        price_button_font_size: "",
        price_text_color: "",
        price_text_font_size: "",
        price_tag_text_color: "",
        price_tag_font_size: "",
        price_tag_title_color: "",
        price_tag_title_font_size: "",
        price_description_text_color: "",
        price_description_font_size: "",
        price_button_color: "",
        price_button_font_color: "",
        price_button_height: "",
        price_button_width: "",
        price_button_right_position: "",
        script_button_text: "",
        prize_popup_status: true,
        price_tag_background_color: "",
        script_button_background_color: "",
        script_button_text_color: "",
        script_button_text_size: "",
        script_button_position_top: "",
        script_button_position_left: "",
        script_button_border_radius: "",
        script_button_height: "",
        script_button_width: "",
        embeded_script: "",
        prize_name_color: "",
        prize_name_font_size: "",
        prize_value_color: "",
        prize_value_font_size: "",
        prize_description_color: "",
        prize_description_font_size: "",
        prize_discount_code_color: "",
        prize_discount_code_size: "",
        prize_badge_color: "",
        prize_badge_text_size: "",
        prize_badge_text_color: "",
        prize_badge_text: "",
        tc_font_color: "",
        tc_font_size: "",
        custom_css: "",
        close_redirect_url: "",
        close_message: "",
      },
      formRules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter name",
          },
        ],
        slug: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter slug",
          },
        ],
        redirect_url: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter redirect URL",
          },
          {
            type: "url",
            trigger: "blur",
            message: "Please enter valid URL",
          },
        ],
        spinner_pin_color: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter hexcode for pin color",
          },
        ],
      },
      otherData: {
        meta_image_url: null,
        campaign_image_url: null,
        selectedOs: [],
        selectedDevice: [],
        selectedBrowser: [],
        selectedCountry: [],
        scriptSelectedCountry: [],
        scriptSelectedDevice: [],
      },
      previewLink: "",
      os: SystemData.os,
      devices: SystemData.devices,
      browsers: SystemData.browsers,
      campaignId: "",
      scriptId: "",
      scriptList: [],
      scriptfields: [
        { title: "Id", dataIndex: "id", key: "id" },
        { title: "Name", dataIndex: "name", key: "name" },
        {
          title: "Position",
          dataIndex: "position",
          slots: { customRender: "position" },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      steps: [
        {
          subTitle: "Settings",
          content: "settings",
          path: "img/wizards/1.svg",
        },
        {
          subTitle: "Layout & content",
          content: "layout",
          path: "img/wizards/1.svg",
        },
        {
          subTitle: "Display & Access Conditions",
          content: "display-conditions",
          path: "img/wizards/1.svg",
        },
        {
          subTitle: "Confirmation",
          content: "confirmation",
          path: "img/wizards/1.svg",
        },
      ],
    };
  },
  methods: {
    async onSubmitPress(formStatus = 0) {
      try {

        console.log(this.form);
        await this.formRef.validate();
        this.isUploading = true;
        const fData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
          if (key) {
            if (key == "spinner") {
              fData.append("spinner", JSON.stringify(value));
            } else if(key == "scratch_prices") {
              fData.append("scratch_prices", JSON.stringify(value));
            } else {
              fData.append(key, value);
            }
          }
        }
        fData.append("os", this.otherData.selectedOs.join(","));
        fData.append("device", this.otherData.selectedDevice.join(","));
        fData.append("browser", this.otherData.selectedBrowser.join(","));
        fData.append("country", this.otherData.selectedCountry.join(","));
        fData.append(
          "script_device",
          this.otherData.scriptSelectedDevice.join(",")
        );
        fData.append(
          "script_country",
          this.otherData.scriptSelectedCountry.join(",")
        );
        fData.append("status", formStatus);

        const dataRes = await API.commitCampaign(fData);
        const { message, status, encrypt_id = "" } = dataRes.data;
        if (message) {
          if (!_.isEmpty(message) && _.isObject(message) && !status) {
            for (const d of Object.values(message)) {
              const msg = d[0];
              if (msg) {
                setTimeout(() => {
                  Notification.show(msg, { type: "error", title: "Error" });
                }, 200);
              }
            }
          } else {
            Notification.show(message, {
              type: status ? "success" : "error",
              title: status ? "Success" : "Error",
            });
          }
        }
        if (status && formStatus == 1) {
          this.campaignId = encrypt_id;
          // this.stepView.next();
          this.currentStep += 1;
          this.isFinished = true;
          this.getPreviewLink();
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.isUploading = false;
        this.confirmModalVisible = false;
      }
    },
    onImageChange(e, type) {
      const file = e?.target?.files[0];
      const blobURL = URL.createObjectURL(file);
      if (type == "meta_image") {
        this.otherData.meta_image_url = blobURL;
        this.form.meta_image = file;
        return null;
      } else if(type == "campaign_image") {
        this.otherData.campaign_image_url = blobURL;
        this.form.campaign_image = file;
        return null;
      }
    },
    handleModalOk() {
      this.isUploading = true;
      this.onSubmitPress(1);
    },
    next() {
      this.formRef.validate().then(() => {
        if (this.currentStep == 0) {
          const nxtKey = this.getNextKeyName();
          if (nxtKey != "") {
            this.settingsActiveKey = this.getNextKeyName();
            if (!this.showPrevBtn) this.showPrevBtn = true;
          } else {
            this.currentStep += 1;
            this.settingsActiveKey = "campaign_details";
            this.showPrevBtn = false;
          }
        } else if (this.currentStep == 1) {
          const nxtKey = this.getNextKeyName();
          if (nxtKey != "") {
            this.layoutActiveKey = this.getNextKeyName();
            if (!this.showPrevBtn) this.showPrevBtn = true;
          } else {
            this.currentStep += 1;
            this.layoutActiveKey = "layout_details";
          }
        } else if (this.currentStep == 2) {
          const nxtKey = this.getNextKeyName();
          if (nxtKey != "") {
            this.displayActiveKey = this.getNextKeyName();
            if (!this.showPrevBtn) this.showPrevBtn = true;
          } else {
            this.currentStep += 1;
            this.displayActiveKey = "display_condition";
          }
        } else {
          this.currentStep += 1;
        }
        this.onSubmitPress(0);
      });
    },
    prev() {
      if (this.currentStep == 0) {
        const prevKey = this.getPreviousKeyName();
        if (prevKey != "") {
          this.settingsActiveKey = prevKey;
        }
        if (this.showPrevBtn && this.settingsActiveKey == "campaign_details") {
          this.showPrevBtn = false;
        }
      } else if (this.currentStep == 1) {
        const prevKey = this.getPreviousKeyName();
        if (prevKey != "") {
          this.layoutActiveKey = prevKey;
          this.showPrevBtn = false;
        } else {
          this.currentStep -= 1;
        }
      } else if (this.currentStep == 2) {
        const prevKey = this.getPreviousKeyName();
        if (prevKey != "") {
          this.displayActiveKey = prevKey;
        } else {
          this.currentStep -= 1;
        }
      } else {
        this.currentStep -= 1;
      }
      if (this.currentStep !== 3) {
        this.isFinished = false;
      }
    },
    settingTabChange(activeKey) {
      if (activeKey == "campaign_details") {
        this.showPrevBtn = false;
      } else if (!this.showPrevBtn) {
        this.showPrevBtn = true;
      }
    },
    onStepChange(c) {
      this.currentStep = c;
      if (c !== 3) {
        this.isFinished = false;
      }
      if (c == 0) {
        this.settingsActiveKey = "campaign_details";
        this.showPrevBtn = false;
      } else if (c == 1) {
        this.layoutActiveKey = "layout_details";
      } else if (c == 2) {
        this.displayActiveKey = "display_condition";
      }
    },
    done() {
      this.confirmModalVisible = true;
    },
    draft() {
      this.onSubmitPress(0);
    },
    back() {
      this.$router.go(this.isEditing ? -1 : -3);
      this.$store.commit("setGame", {});
      this.$store.commit("setTheme", {});
    },
    getNextKeyName() {
      if (this.currentStep == 0) {
        let sKey = "";
        if (this.settingsActiveKey == "campaign_details") {
          sKey = "meta_details";
        } else if (this.settingsActiveKey == "meta_details") {
          sKey = "power_by_and_terms";
        } else if (this.settingsActiveKey == "power_by_and_terms") {
          sKey = "winners_n_prizes";
        } else if (this.settingsActiveKey == "winners_n_prizes") {
          sKey = "front_scripts";
        }
        return sKey;
      } else if (this.currentStep == 1) {
        let lKey = "";
        if (this.layoutActiveKey == "layout_details") {
          lKey = "content_details";
        } else if (this.layoutActiveKey == "content_details") {
          lKey = "participant_details";
        } else if (this.layoutActiveKey == "participant_details") {
          lKey = "teaser_settings";
        }
        return lKey;
      } else if (this.currentStep == 2) {
        let lKey = "";
        if (this.displayActiveKey == "display_condition") {
          lKey = "display_message";
        }
        return lKey;
      } else {
        return "";
      }
    },
    getPreviousKeyName() {
      if (this.currentStep == 0) {
        let sKey = "";
        if (this.settingsActiveKey == "meta_details") {
          sKey = "campaign_details";
        } else if (this.settingsActiveKey == "power_by_and_terms") {
          sKey = "meta_details";
        } else if (this.settingsActiveKey == "winners_n_prizes") {
          sKey = "power_by_and_terms";
        } else if (this.settingsActiveKey == "front_scripts") {
          sKey = "winners_n_prizes";
        }
        return sKey;
      } else if (this.currentStep == 1) {
        let lKey = "";
        if (this.layoutActiveKey == "content_details") {
          lKey = "layout_details";
        } else if (this.layoutActiveKey == "participant_details") {
          lKey = "content_details";
        } else if (this.layoutActiveKey == "teaser_settings") {
          lKey = "participant_details";
        }
        return lKey;
      } else if (this.currentStep == 2) {
        let lKey = "";
        if (this.displayActiveKey == "display_message") {
          lKey = "display_condition";
        }
        return lKey;
      } else {
        return "";
      }
    },
    openFrontPage() {
      const encrypt_id = this.$route.params.id;
      const campaign_domain = this.form.campaign_domain;
      const slug = this.form.slug;

      if (process.env.NODE_ENV == "development") {
        const routeData = this.$router.resolve({
          path: `/${slug}`,
        });
        window.open(routeData.href, "_blank");
      } else {
        if (!campaign_domain && encrypt_id) {
          window.open(
            `${process.env.VUE_APP_DOMAIN}${slug}`,
            "_blank"
          );
        } else {
          window.open(`${campaign_domain}${slug}`, "_blank");
        }
      }
    },
    getPreviewLink() {
      const encrypt_id = this.isEditing
        ? this.$route.params.id
        : this.campaignId;
      const campaign_domain = this.form.campaign_domain;
      const slug = this.form.slug;
      let link = "";
      if (process.env.NODE_ENV == "development") {
        const routeData = this.$router.resolve({
          path: `/${slug}`,
        });
        link = `${location.origin}${routeData.href}`;
      } else {
        if (!campaign_domain && encrypt_id) {
          const routeData = this.$router.resolve({
            path: `/${slug}`,
          });
          link = `${location.origin}${routeData.href}`;
        } else {
          link = `${campaign_domain}${slug}`;
        }
      }
      this.previewLink = link;
    },
    addScript() {
      this.scriptId = "";
      this.addScriptVisible = true;
    },
    cancelScriptModal() {
      //this.formRef.clearValidate()
      this.script_name = "";
      this.script_position = "";
      this.script = "";
    },
    async handleScriptAddModal() {
      try {
        const campaignId = this.$route.params.id;

        const param = {
          id: this.scriptId,
          campaign_id: campaignId,
          name: this.script_name,
          position: this.script_position,
          script: this.script,
        };
        this.campaignId = campaignId;

        const updateRes = await API.commitCampaignScript(param);
        const { status, message } = updateRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          this.addScriptVisible = false;
          this.script_name = "";
          this.script_position = "";
          this.script = "";
          this.getScripts();
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    async getScripts() {
      try {
        const detRes = await API.getCampaignsScripts({
          params: { campaign_id: this.$route.params.id },
        });
        const { data, status, message } = detRes.data;
        if (status) {
          this.scriptList = data;
        } else {
          if (message) {
            Notification.show(message, { type: "error", title: "Error" });
          }
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    onServiceupdate(record) {
      this.scriptId = record.encrypt_id;
      this.script_name = record.name;
      this.script_position = record.position;
      this.script = record.script;
      this.addScriptVisible = true;
    },
    onServiceRemove(id) {
      this.scriptId = id;
      this.deleteScriptConfirmVisible = true;
    },
    async handleScriptDeleteModal() {
      try {
        this.isDeleting = true;
        const delRes = await API.deleteCampaignScripts({ id: this.scriptId });
        const { status, message } = delRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          this.deleteScriptConfirmVisible = false;
          this.getScripts();
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.isDeleting = false;
        this.deleteScriptConfirmVisible = false;
      }
    },
    async getCamapignSlug() {
      let slug = this.form.name;
      let str     = slug.toLowerCase();
      let ans     = str.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');

      this.form.slug = ans;
    },
    async setCampaignSlug() {
      if(this.form.slug == "") {
        this.getCamapignSlug();
      }
    },
    async showCloseDetail() {

      const closeC = await this.form.close_campaign;
      if(closeC) {
        this.showCloseBlock = true;
      } else {
        this.showCloseBlock = false;
      }
    }

  },
  watch: {
    initialData: {
      handler(newData) {
        this.form = {
          ...this.form,
          ...newData,
          winning_email:
            !!newData.winning_email && JSON.parse(newData.winning_email),
        };
      },
      deep: true,
    },
    formData: {
      handler(newData) {
        this.form = {
          ...this.form,
          ...newData,
        };
      },
      deep: true,
    },
    otherFormData: {
      handler(newFormData) {
        this.otherData = {
          ...this.otherData,
          ...newFormData,
        };
      },
      deep: true,
    },
  },
  created() {
    this.getScripts();
  },
  mounted() {
    let $this = this;
    setTimeout(function() {
      $this.setCampaignSlug()
      $this.showCloseDetail();
    }, 1200);
  }
};
</script>