<template>
  <StepsStyle
    v-if="steps !== undefined"
    v-model:current="currents"
    :direction="direction"
    :status="status"
    :size="size"
    @change="onStepChange"
  >
    <a-step
      v-for="item in steps"
      :class="item.class && item.class"
      :icon="item.icon && item.icon"
      :key="item.title"
      :title="item.title"
      :description="item.titleDescription"
    >
      <template #description>
        <div v-if="item.path" class="wizard-item">
          <h2>{{ item.subTitle }}</h2>
          <p>{{ item.description }}</p>
          <!-- <img :src="require('../../assets/' + item.path)" alt="" /> -->
        </div>
      </template>
    </a-step>
  </StepsStyle>

  <div v-if="isVertical" class="steps-wrapper">
    <div
      class="steps-content"
      :style="{ minHeight: height, display: 'flex', justifyContent: 'center', marginTop: 100 }"
    >
      <slot :name="steps[currents].content"></slot>
    </div>
    <ActionWrapper v-if="!isFinished">
      <div class="step-action-wrap">
        <div class="step-action-inner">
          <a-row>
            <a-col :xs="24">
              <div class="steps-action">
                <sdButton v-if="(currents > 0 && currents !== steps.length - 1) || showPrevBtn" class="btn-prev" type="light" @click="() => prev()">
                  <sdFeatherIcons type="arrow-left" size="16" />
                  Previous
                </sdButton>

                <sdButton style="margin-right: 10px;" v-if="currents < steps.length - 1" class="btn-next" type="primary" @click="onPreview">
                  Preview
                  <sdFeatherIcons type="eye" size="16" />
                </sdButton>

                <sdButton style="margin-right: 10px;" type="primary" @click="onDraft">
                  Save as Draft
                </sdButton>

                <sdButton v-if="currents < steps.length - 2" class="btn-next" type="primary" @click="() => next()">
                  Save & Next
                  <sdFeatherIcons type="arrow-right" size="16" />
                </sdButton>

                <sdButton v-if="currents === steps.length - 2" type="primary" @click="onDone">
                  Save & Publish
                </sdButton>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </ActionWrapper>
  </div>
</template>
<script>
import { toRefs, ref, watch } from 'vue';
import VueTypes from 'vue-types';
import { StepsStyle, ActionWrapper } from './style';

const stepStyle = {
  marginBottom: 60,
  boxShadow: '0px -1px 0 0 #e8e8e8 inset',
};

const Steps = {
  name: 'Steps',
  components: { StepsStyle, ActionWrapper },
  props: {
    size: VueTypes.string.def('default'),
    current: VueTypes.number.def(0),
    direction: VueTypes.string.def('horizontal'),
    status: VueTypes.string,
    description: VueTypes.string,
    //progressDot: VueTypes.func.def(() => 1),
    steps: VueTypes.arrayOf(VueTypes.object),
    wizardItem: VueTypes.arrayOf(VueTypes.object),
    isSwitch: VueTypes.bool.def(false),
    navigation: VueTypes.bool.def(false),
    isFinished: VueTypes.bool.def(false),
    isVertical: VueTypes.bool.def(false),
    showPrevBtn: VueTypes.bool.def(false),
    height: VueTypes.number.def(150),
  },
  emits: ['onPrev', 'onNext', 'onDone', 'onChange', 'onDraft', 'onPreview'],
  setup(props, { emit }) {
    const { current, isFinished } = toRefs(props);
    const currents = ref(current.value);
    const finished = isFinished.value;
    watch(() => current.value,
      (count) => {
        currents.value = count;
      }
    )

    const next = () => {
      emit('onNext');
    };

    const onDraft = () => {
      emit('onDraft');
    };

    const prev = () => {
      emit('onPrev');
    };

    const onDone = () => {
      emit('onDone');
    };

    const onPreview = () => {
      emit('onPreview');
    };

    const onStepChange = (v) => {
      if (!finished && v == 3) {
        currents.value = v - 1;
      }
      emit('onChange', currents.value);
    }

    return {
      prev,
      next,
      onDraft,
      currents,
      stepStyle,
      onDone,
      onPreview,
      onStepChange
    };
  },
};

export default Steps;
</script>
